@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800|Roboto+Slab:400,100,300,700);
/**  Basic Page CSS **/
body{
  font-family: "Roboto Slab", sans-serif;
  font-weight: 300;
  font-size: 2em;
  position: relative;
  background-color: #337ab7;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  margin: 0;
}

/**  Header Navigation **/

header .navbar {
  margin-bottom: 0;
}

header .navbar-default {
  background-color: #337ab7;
  border: none;
  border-radius: 0;
  padding: 10px 0;
}

/**  Navbar Brand **/

header .navbar-brand {
  background: url('../images/wisdompetlogo.svg');
  background-repeat: no-repeat;
  background-position: 15px 0;
}

header .navbar-brand h1 {
  color: white;
  margin: 0;
  font-size: 1.1em;
  font-weight: 400;
  padding-left: 70px;
}

.main {
  background: white;
  padding: 30px 0;
}
/**  Footer **/

footer {
  background: #337ab7;
  color: white;
  font-size: .8em;
  padding: 20px 0 40px 0;
}

footer a {
  color: #E0E6AE;
}

footer a:hover {
  color: #EEC856 !important;
}

footer .phone {
  font-size: 1.4em;
  font-weight: 700;
  color: #E0E6AE;
}

footer .navbar {
  margin-bottom: 0;
  min-height: 0;
}

footer .navbar-default {
  background-color: transparent;
  border: none;
  margin-right: 40px;
}

footer .navbar-nav>li a {
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: underline;
  display:inline-block;
  color: #EEC856 !important;
}


/**  Interface Styles **/

.add-appointment .card-body {
  display: none;
  transition: display 0.3s ease-in-out;
  -webkit-transition: display 0.3s ease-in-out;
  -moz-transition: display 0.3s ease-in-out;
  -ms-transition: display 0.3s ease-in-out;
  -o-transition: display 0.3s ease-in-out;
}

.apt-addheading {
  cursor: pointer;
  user-select: none;
}

.appointment-list {
  font-size: 1.1em;
}

.appointment-list .pet-item {
  border-bottom: 1px dotted gray;
}

.appointment-list .pet-item:last-child {
  border-bottom: none;
}

.appointment-list .pet-name {
  font-weight: 600;
  color: #337ab7;
  font-size: 1.3em;
  line-height: 100%;
}

.appointment-list .label-item {
  font-weight: 600;
  color: #667b82;
}

.appointment-list .apt-date {
  font-style: italic;
}

.appointment-list .apt-notes {
  line-height: 120%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #a9aeb2;
}